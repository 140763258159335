import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoogleMap, { Map } from "google-maps-react-markers";
import Draggable from "react-draggable";
import Icon, { Stack } from "@mdi/react";
import { mdiAlphaHCircleOutline, mdiArrowExpand, mdiBroadcast, mdiChevronLeft, mdiCircle, mdiCursorMove, mdiMinusCircle, mdiSwapVerticalCircle } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";

import Layout from "../../components/Layout";
import Marker from "../../components/Marker";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconLogo } from "../../icons/logo_icone.svg";
import VideoJS from "../../components/VideoJS";
import { Textos } from "../../config/defines";

const toastTitle = "Live";

export default function Live() {
	const navigate = useNavigate();
	const { vooId } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const containerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<Map>();

	const [mapRecenter, setMapRecenter] = useState(true);
	const [formHomeLatitude, setFormHomeLatitude] = useState<number>();
	const [formHomeLongitude, setFormHomeLongitude] = useState<number>();
	const [formLatitude, setFormLatitude] = useState<number>();
	const [formLongitude, setFormLongitude] = useState<number>();
	const [formRota, setFormRota] = useState<google.maps.Polygon>();

	const [pip, setPIP] = useState(false);
	const [size, setSize] = useState(true);
	const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0 });
	const [mapPosition, setMapPosition] = useState({ x: 10, y: 10 });
	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);

	const queryLives = useQuery(["lives"], () => fetchDataLives(), {
		refetchOnWindowFocus: false,
		refetchInterval: 10000,
	});

	const data = queryLives.data?.Data?.find((item) => {
		return Number(item.vooId) === Number(vooId);
	});

	useQuery(["cliente", "home", data?.cliente.id], () => fetchDataClienteHome(data?.cliente.id));
	useQuery(["missao", data?.missao.id, "rota"], () => fetchDataMissaoRota(data?.missao.id), {
		enabled: !!data?.missao.id,
	});
	useQuery(["missao", data?.missao.id, "home"], () => fetchDataMissaoHome(data?.missao.id), {
		enabled: !!data?.missao.id,
	});

	async function fetchDataClienteHome(clienteId: any) {
		let resp = await apiService.getHome(clienteId);
		if (resp.Result === 1 && resp.Data) {
			setFormHomeLatitude(resp.Data.latitude);
			setFormHomeLongitude(resp.Data.longitude);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	async function fetchDataLives() {
		let resp = await apiService.GetLives();
		if (resp.Result === 1 && resp.Data) {
			let respData = resp.Data?.find((item) => {
				return Number(item.vooId) === Number(vooId);
			});
			if (respData === undefined) {
				handleVoltar();
			} else {
				setFormLatitude(respData?.lat);
				setFormLongitude(respData?.lng);
				if (mapRef.current && mapRecenter) {
					mapRef.current.setCenter({ lat: respData.lat, lng: respData.lng });
					setMapRecenter(false);
				}
			}
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return resp;
	}

	async function fetchDataMissaoRota(missaoId: any) {
		let resp = await apiService.getRotaMissao(missaoId);
		if (resp.Result === 1 && resp.Data) {
			if (mapRef.current) {
				let rota = new google.maps.Polygon({
					paths: resp.Data.map((item) => {
						return { lat: item.latitude, lng: item.longitude };
					}),
					strokeColor: "#dc3545",
					strokeOpacity: 1,
					strokeWeight: 2,
					fillOpacity: 0,
				});
				var bounds = new google.maps.LatLngBounds();
				var paths = rota.getPaths();
				var path;
				if (paths.getLength() >= 1) {
					for (var i = 0; i < paths.getLength(); i++) {
						path = paths.getAt(i);
						for (var ii = 0; ii < path.getLength(); ii++) {
							bounds.extend(path.getAt(ii));
						}
					}
					mapRef.current.fitBounds(bounds);
				}

				setFormRota(rota);
			}
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}
		return resp;
	}

	async function fetchDataMissaoHome(missaoId: any) {
		let resp = await apiService.GetHomeMissao(missaoId);
		if (resp.Result === 1 && resp.Data) {
			setFormHomeLatitude(resp.Data.latitude);
			setFormHomeLongitude(resp.Data.longitude);
		}
		return resp;
	}

	const videoJsOption = useMemo(() => {
		return {
			autoplay: true,
			playsinline: true,
			controls: true,
			responsive: true,
			liveui: true,
			fill: true,
			sources: data?.parametro.liveURL,
			poster: process.env.PUBLIC_URL + "/logo_escuro.png",
		};
	}, [data?.parametro.liveURL]);

	useEffect(() => {
		if (formRota && mapRef.current) {
			formRota.setMap(mapRef.current);
		}

		return () => {
			if (formRota) {
				formRota.setMap(null);
			}
		};
		// eslint-disable-next-line
	}, [formRota, mapRef.current]);

	useEffect(() => {
		if (containerRef.current) {
			setContainerWidth(containerRef.current.clientWidth);
			setContainerHeight(containerRef.current.clientHeight);
		}
		// eslint-disable-next-line
	}, [containerRef.current]);

	function handleMapLoad({ map, maps }: { map: Map; maps: any }) {
		mapRef.current = map;
	}

	function handleAlterar() {
		setPIP(!pip);
		setVideoPosition(mapPosition);
		setMapPosition(videoPosition);
	}

	function handleSize() {
		setSize(!size);
	}

	function handleVoltar() {
		navigate("/lives");
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/lives"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiBroadcast} size={1} className="me-1" />
					Live
				</Link>
			</h5>

			<Row>
				<Col className="pe-0">
					<Card className="mb-4">
						<Card.Body style={{ height: "calc(100dvh - 82px - 4.5rem)" }}>
							<div ref={containerRef} className="position-relative" style={{ height: "100%" }}>
								<Draggable
									handle=".handle"
									position={videoPosition}
									onDrag={(_, position) => {
										setVideoPosition(position);
									}}
									scale={1}
									bounds="parent"
									disabled={!pip}
								>
									<div
										className={`d-inline-block position-absolute ${pip ? "rounded overflow-hidden shadow" : ""}`}
										style={pip ? { width: 210, height: 210, zIndex: 2 } : { width: containerWidth, height: containerHeight, zIndex: 1 }}
									>
										{pip && (
											<div className={`d-flex ${size ? "position-absolute" : ""}  bg-white p-1 shadow-sm rounded small`} style={{ zIndex: 3 }}>
												<div className="handle" style={{ width: 20, cursor: "move" }}>
													<Stack>
														<Icon path={mdiCircle} size={1} />
														<Icon path={mdiCursorMove} size={0.6} className="text-white" />
													</Stack>
												</div>
												<div onClick={handleSize} style={{ width: 20, cursor: "pointer" }}>
													{size ? (
														<Icon path={mdiMinusCircle} size={0.8} />
													) : (
														<Stack>
															<Icon path={mdiCircle} size={1} />
															<Icon path={mdiArrowExpand} size={0.6} className="text-white" />
														</Stack>
													)}
												</div>
												<div onClick={handleAlterar} style={{ width: 20, cursor: "pointer" }}>
													<Icon path={mdiSwapVerticalCircle} size={0.8} />
												</div>
											</div>
										)}
										<VideoJS options={videoJsOption} className="bg-dark" style={{ width: "100%", height: "100%" }} />
									</div>
								</Draggable>
								<Draggable
									handle=".handle"
									position={mapPosition}
									onDrag={(_, position) => {
										setMapPosition(position);
									}}
									scale={1}
									bounds="parent"
									disabled={pip}
								>
									<div
										className={`d-inline-block position-absolute ${!pip ? "rounded overflow-hidden shadow" : ""}`}
										style={!pip ? { width: size ? 210 : "auto", height: size ? 210 : "auto", zIndex: 2 } : { width: containerWidth, height: containerHeight, zIndex: 1 }}
									>
										{!pip && (
											<div className={`d-flex ${size ? "position-absolute" : ""}  bg-white p-1 shadow-sm rounded small`} style={{ zIndex: 3 }}>
												<div className="handle" style={{ width: 20, cursor: "move" }}>
													<Stack>
														<Icon path={mdiCircle} size={1} />
														<Icon path={mdiCursorMove} size={0.6} className="text-white" />
													</Stack>
												</div>
												<div onClick={handleSize} style={{ width: 20, cursor: "pointer" }}>
													{size ? (
														<Icon path={mdiMinusCircle} size={0.8} />
													) : (
														<Stack>
															<Icon path={mdiCircle} size={1} />
															<Icon path={mdiArrowExpand} size={0.6} className="text-white" />
														</Stack>
													)}
												</div>
												<div onClick={handleAlterar} style={{ width: 20, cursor: "pointer" }}>
													<Icon path={mdiSwapVerticalCircle} size={0.8} />
												</div>
											</div>
										)}
										<div className={`${!size ? "d-none" : ""}`} style={{ width: "100%", height: "100%" }}>
											{formLatitude && formLongitude && (
												<GoogleMap
													apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
													defaultZoom={14}
													defaultCenter={{ lat: formLatitude!, lng: formLongitude! }}
													options={{ fullscreenControl: false, streetViewControl: false, zoomControlOptions: { position: 21 }, mapTypeControlOptions: { position: 20 } }}
													onGoogleApiLoaded={handleMapLoad}
													style={{ height: "100%" }}
												>
													<Marker lat={formHomeLatitude} lng={formHomeLongitude} style={{ zIndex: 9 }}>
														<Icon
															path={mdiAlphaHCircleOutline}
															size={1.5}
															className="text-danger bg-white rounded-circle"
															style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
														/>
													</Marker>
													<Marker lat={formLatitude} lng={formLongitude} style={{ zIndex: 1100 }}>
														<IconSVG path={IconLogo} style={{ width: 35, height: 35, filter: "drop-shadow( 0 0 4px rgba(0, 0, 0, 1))" }} />
													</Marker>
												</GoogleMap>
											)}
										</div>
									</div>
								</Draggable>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col lg="auto" className="pb-4" style={{ width: 250 }}>
					<Card style={{ height: "100%" }}>
						<Card.Body className="small">
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>{Textos[`${process.env.REACT_APP_TENANT}`].cliente}</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.cliente.nome}</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Missão</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.missao.nome ?? "-"}</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Drone</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.drone.nome}</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Altura</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.altura.toFixed(1)} <span className="text-muted small">m</span>
								</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Velocidade</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.velocidade.toFixed(1)} <span className="text-muted small">m/s</span>
								</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Latitude</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.lat}&nbsp;</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Longitude</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.lng}&nbsp;</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Bateria iPhone</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.bateriaMobile} <span className="text-muted small">%</span>
								</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Bateria</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.bateria} <span className="text-muted small">%</span>
								</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Bateria RC</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.bateriaRc} <span className="text-muted small">%</span>
								</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Sinal GPS (Quantidade)</div>
								<div className={`fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.gpsSignal}
									<span className="text-muted small">/5</span> ({data?.gpsCount})&nbsp;
								</div>
							</Form.Group>
							<Form.Group className="mb-1">
								<div className={`fw-lighter ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Status</div>
								<div className={`d-flex gap-1 fw-light ${!queryLives.isFetched ? "skeleton skeleton-text skeleton-text-title" : ""}`}>
									{data?.decolando === 1 && <Badge>Decolando</Badge>}
									{data?.pousando === 1 && <Badge>Pousando</Badge>}
									{data?.retornando === 1 && <Badge>Retornando</Badge>}
									{data?.pausado === 1 && <Badge>Pausado</Badge>}
									{data?.voando === 1 && <Badge>Voando</Badge>}
								</div>
							</Form.Group>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Layout>
	);
}
