import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { Textos } from "../../config/defines";

type HeaderProps = {
	cliente?: boolean;
};

export default function Header(props: HeaderProps) {
	const { user, cliente } = useAuth();
	const navigate = useNavigate();

	return (
		<>
			<Row className="topbar" style={{ minHeight: 56 }}>
				<Col className="fs-4 fw-lighter pt-2 pb-md-2 d-flex align-items-center">Seja bem-vindo {user?.nome}</Col>
				{props.cliente && user?.cliente && (
					<Col xs="auto" md={6} lg={5} xl={4} className="fs-6 fw-lighter pt-2 pb-2 text-end">
						<Button
							variant={"outline-dark"}
							onClick={() => {
								navigate("/resumo");
							}}
						>
							{cliente ? cliente.nome : `Selecionar ${Textos[`${process.env.REACT_APP_TENANT}`].cliente}`}
						</Button>
					</Col>
				)}
			</Row>
		</>
	);
}
