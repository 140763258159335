import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMaskInput } from "react-imask";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiAccountTieHatOutline, mdiChevronLeft, mdiEye, mdiEyeOff } from "@mdi/js";

import { CPFMask, CelularMask, TelefoneMask, Textos } from "../../config/defines";
import { SelectStyle } from "../../config/select";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { PilotoModel } from "../../models/PilotoModel";

import Layout from "../../components/Layout";

const toastTitle = "Piloto";

export default function PilotoFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const clienteAtual = user!.getCliente(cliente!.id);
	const empresaAtual = user!.getEmpresaByCliente(cliente!.id);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formEmpresa, setFormEmpresa] = useState<{ label: string; value: number } | null>(id ? null : { label: empresaAtual!.nome, value: empresaAtual!.id });
	const [formCliente, setFormCliente] = useState<{ label: string; value: number } | null>(id ? null : { label: clienteAtual!.nome, value: clienteAtual!.id });
	const [formUsuario, setFormUsuario] = useState<string>();
	const [formNome, setFormNome] = useState<string>();
	const [formSenha, setFormSenha] = useState<string>();
	const [senhaExibir, setSenhaExibir] = useState(false);
	const [formCodigo, setFormCodigo] = useState<string>();
	const [formCPF, setFormCPF] = useState<string>();
	const [formRG, setFormRG] = useState<string>();
	const [formTelefone, setFormTelefone] = useState<string>();
	const [formCelular, setFormCelular] = useState<string>();
	const [formEmail, setFormEmail] = useState<string>();
	const [formPermissoes, setFormPermissoes] = useState<number[]>();
	const [formIsAdm, setFormIsAdm] = useState(false);

	const { isLoading, isFetching, isRefetching, refetch } = useQuery(["piloto", id], () => fetchData(id), { enabled: id !== undefined });
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let respCadastro = await apiService.GetCadastroPiloto(id);

		if (respCadastro.Result === 1 && respCadastro.Data) {
			if (respCadastro.Data!.empresaId) {
				let usuarioEmpresa = user?.getEmpresa(respCadastro.Data!.empresaId);
				if (usuarioEmpresa) {
					setFormEmpresa({ label: usuarioEmpresa.nome, value: usuarioEmpresa.id });
				}
			}
			if (respCadastro.Data!.clienteId) {
				let usuarioCliente = user?.getCliente(respCadastro.Data!.clienteId);
				if (usuarioCliente) {
					setFormCliente({ label: usuarioCliente.nome, value: usuarioCliente.id });
				}
			}
			setFormUsuario(respCadastro.Data.usuario);
			setFormNome(respCadastro.Data.nome);
			setFormSenha(respCadastro.Data.senha);
			setFormCodigo(respCadastro.Data.codigo);
			setFormCPF(respCadastro.Data.cpf);
			setFormRG(respCadastro.Data.rg);
			setFormTelefone(respCadastro.Data.telefone);
			setFormCelular(respCadastro.Data.celular);
			setFormEmail(respCadastro.Data.email);
			setFormPermissoes(respCadastro.Data.permissoes);
			if (respCadastro.Data.isAdm !== undefined) {
				setFormIsAdm(respCadastro.Data.isAdm);
			}
		} else {
			handleToast(toastTitle, respCadastro.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: PilotoModel = {
			empresaId: formEmpresa?.value,
			clienteId: formCliente?.value,
			id: id ? Number(id) : 0,
			usuario: formUsuario!,
			nome: formNome!,
			senha: formSenha!,
			codigo: formCodigo!,
			cpf: formCPF!,
			rg: formRG!,
			telefone: formTelefone!,
			celular: formCelular!,
			email: formEmail!,
			permissoes: formPermissoes!,
		};

		mutation.mutate(data);
	}

	async function mutateData(data: PilotoModel) {
		return await apiService.PostPiloto(data, formCliente?.value, formEmpresa?.value);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["piloto", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/pilotoFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/pilotos");
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/pilotos"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiAccountTieHatOutline} size={1} className="me-1" />
					Formulário de Piloto
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Form onSubmit={handleSubmit}>
				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="empresa">
							<Form.Label>{Textos[`${process.env.REACT_APP_TENANT}`].empresa}</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formEmpresa}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={user!.empresa.map((empresa: any) => {
									return { label: empresa.nome, value: empresa.id };
								})}
								onChange={(value) => {
									setFormEmpresa(value);
									setFormCliente(null);
								}}
								isDisabled={!formStatus}
								styles={SelectStyle}
							/>
						</Form.Group>
						{formIsAdm !== true && (
							<Form.Group className="mb-3" controlId="cliente">
								<Form.Label>{Textos[`${process.env.REACT_APP_TENANT}`].cliente}</Form.Label>
								<Select
									placeholder={"Selecione"}
									className="fs-6 bg-white rounded"
									value={formCliente}
									noOptionsMessage={() => {
										return "Nenhuma opção";
									}}
									options={user!.cliente
										.filter((cliente) => cliente.empresaId === formEmpresa?.value)
										.map((cliente) => {
											return { label: cliente.nome, value: cliente.id };
										})}
									onChange={(value) => {
										setFormCliente(value);
									}}
									isDisabled={!formStatus}
									isClearable={true}
									styles={SelectStyle}
								/>
							</Form.Group>
						)}
						<Form.Group className="mb-3" controlId="usuario">
							<Form.Label>Usuário</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o usuário"
								value={formUsuario}
								onChange={(event) => {
									setFormUsuario(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="senha">
							<Form.Label>Senha</Form.Label>
							<InputGroup>
								<Form.Control
									type={senhaExibir ? "text" : "password"}
									placeholder="Informe aqui a senha"
									value={formSenha}
									onChange={(event) => {
										setFormSenha(event.target.value);
									}}
									disabled={!formStatus}
								/>
								<Button
									variant="light"
									className="border"
									disabled={!formStatus}
									onClick={() => {
										setSenhaExibir(!senhaExibir);
									}}
								>
									<Icon path={senhaExibir ? mdiEye : mdiEyeOff} size={1} />
								</Button>
							</InputGroup>
							{id && <Form.Text className="d-block">Caso não queira trocar a senha, manter o campo em branco.</Form.Text>}
						</Form.Group>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="codigo">
							<Form.Label>Código</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o código"
								value={formCodigo}
								onChange={(event) => {
									setFormCodigo(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="cpf">
							<Form.Label>CPF</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={CPFMask}
								type="text"
								placeholder="Informe aqui o CPF"
								value={formCPF}
								onChange={(event) => {
									setFormCPF(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="RG">
							<Form.Label>RG</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o RG"
								value={formRG}
								onChange={(event) => {
									setFormRG(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="telefone">
							<Form.Label>Telefone</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={TelefoneMask}
								type="text"
								placeholder="Informe aqui o telefone"
								value={formTelefone}
								onChange={(event) => {
									setFormTelefone(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="celular">
							<Form.Label>Celular</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={CelularMask}
								type="text"
								placeholder="Informe aqui o celular"
								value={formCelular}
								onChange={(event) => {
									setFormCelular(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label>E-mail</Form.Label>
							<Form.Control
								type="email"
								placeholder="Informe aqui o e-mail"
								value={formEmail}
								onChange={(event) => {
									setFormEmail(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
					</Card.Body>
				</Card>

				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Permissões</Card.Header>
					<Card.Body>
						<Row>
							{user!.permissoes.map((permissao, key: number) => {
								return (
									<Col lg={4} key={key}>
										<Form.Group className="mb-3" controlId={permissao.codigo}>
											<Form.Label>{permissao.nome}</Form.Label>
											<Form.Check
												type="switch"
												checked={formPermissoes?.includes(permissao.id)}
												onChange={(e) => {
													if (e.currentTarget.checked) {
														setFormPermissoes([...(formPermissoes ?? []), permissao.id]);
													} else {
														setFormPermissoes(
															formPermissoes?.filter((item) => {
																return item !== permissao.id;
															})
														);
													}
												}}
												disabled={!formStatus}
											/>
										</Form.Group>
									</Col>
								);
							})}
						</Row>
					</Card.Body>
				</Card>

				<div className="mb-4">
					{!formStatus ? (
						<Button
							className="me-2"
							variant="dark"
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setFormStatus(true);
							}}
						>
							Editar Informações
						</Button>
					) : (
						<>
							<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
								{formSaving ? (
									<>
										<Spinner animation="border" size="sm" className="me-2" /> Salvando
									</>
								) : (
									"Salvar Informações"
								)}
							</Button>
							{id && (
								<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
									Cancelar
								</Button>
							)}
						</>
					)}
				</div>
			</Form>
		</Layout>
	);
}
