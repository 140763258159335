export const CPFMask = "000.000.000-000";
export const CNPJMask = "00.000.000/0000-00";
export const TelefoneMask = "(00) 00000-0000";
export const CelularMask = "(00) 00000-0000";

export function convertBeaufortValue(speed: number) {
	const table = [0.3, 1.6, 3.4, 5.5, 8, 10.8, 13.9, 17.2, 20.8, 24.5, 28.5, 32.7].map((v, i) => {
		return { i: i, v: v };
	});
	let flt = table.filter((x) => speed < x.v);
	if (flt.length === 0) return 12;
	return flt[0].i;
}

export function convertBeaufortDescription(beau: number) {
	const descs = [
		"Calmo",
		"Aragem",
		"Brisa leve",
		"Brisa fraca",
		"Brisa moderada",
		"Brisa forte",
		"Vento fresco",
		"Vento forte",
		"Ventania",
		"Ventania forte",
		"Tempestade",
		"Tempestade violenta",
		"Furacão",
	];
	return descs[beau];
}

export function convertBeaufortColor(beau: number) {
	const classes = ["text-primary", "text-warning", "text-danger"];
	let index = Math.floor(beau / classes.length);
	if (index >= classes.length) index = classes.length - 1;
	return classes[index];
}

export function calcularMMC(num1: number, num2: number) {
	let resto, x, y;
	x = num1;
	y = num2;
	while (resto !== 0) {
		resto = x % y;
		x = y;
		y = resto;
	}
	return (num1 * num2) / x;
}

export function gridTemplate(screensLength: number) {
	if (screensLength === 0) {
		return {
			gridTemplateColumns: `repeat(1, 1fr)`,
			gridColumns: [`span 1`],
		};
	}

	let screensShape = Math.floor(Math.sqrt(screensLength));
	let screensColumns = Math.floor(screensLength / screensShape);
	let screensRows = screensShape;
	let screensLastRow = screensRows === 1 ? screensLength : screensColumns * (screensRows - 1);
	let gridColumns = screensLength - screensLastRow > 0 ? calcularMMC(screensColumns, screensLength - screensLastRow) : screensColumns;

	return {
		gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
		gridColumns: Array.from({ length: screensLength }).map((_: any, index: number) => {
			return index > screensLastRow - 1 ? `span ${gridColumns / (screensLength - screensLastRow)}` : `span ${gridColumns / screensColumns}`;
		}),
	};
}

export function formatDistance(distance: number) {
	let label = "m";
	let value = distance;
	if (distance > 1000) {
		value = value / 1000;
		label = "km";
	}

	return {
		label: label,
		value: value,
	};
}

export const Textos: { [key: string]: Record<string, string> } = {
	AIR: {
		empresa: "Empresa",
		empresas: "Empresas",
		cliente: "Cliente",
		clientes: "Clientes",
	},
	BMRS: {
		empresa: "Comando",
		empresas: "Comandos",
		cliente: "Unidade",
		clientes: "Unidades",
	},
};
