import axios, { AxiosRequestConfig } from "axios";
import { ApiResponseType } from "../entities/ApiResponseEntity";
import moment from "moment-timezone";
import { LoginPostData, LoginReplyModel } from "../models/LoginModel";
import { AdminSlim, CadastroAdmin, PostAdmin } from "../models/AdminModel";
import { AlertaQueryInput, AlertaSearchInput, AlertaLiteOutput, AlertaOutput } from "../models/AlertaModel";
import { Bateria, AlertaBateria, PostBateria } from "../models/BateriaModel";
import { ClienteSlim, CadastroCliente } from "../models/ClienteModel";
import { CoordinatesModel } from "../models/CoordinatesModel";
import { DroneExtModel, DroneModel } from "../models/DroneModel";
import { PostEmpresa } from "../models/EmpresaModel";
import { LogDiarioLiteModel, LogDiarioModel } from "../models/LogDiarioModel";
import { MediaParameters, MediaModel } from "../models/MediaModel";
import { MissaoLogDetalhesModel, MissaoLogImagensModel, MissaoLogModel } from "../models/MissaoLogModel";
import { MissaoModel, MissaoWaypoint } from "../models/MissaoModel";
import { NamedEntity, NamedCodedEntity } from "../models/NamedEntity";
import { PageModel } from "../models/PageModel";
import { ParametroModel } from "../models/ParametroModel";
import { PilotoGetModel, PilotoModel, PilotoUsuarioModel } from "../models/PilotoModel";
import { Relatorio, PostRelatorio, ArquivoRelatorio } from "../models/Relatorio";
import { ResumoClienteModel, ResumoDroneModel, ResumoPilotoModel } from "../models/ResumoClienteModel";
import { RotaMissaoModel } from "../models/RotaMissaoModel";
import { StatusVooCoordsModel, StatusVooModel } from "../models/StatusVooModel";
import { VerificaVideoModel } from "../models/VerificaVideoModel";
import { WeatherModel } from "../models/WeatherModel";
import { LiveViewModel } from "../models/LiveViewModel";

/* API */
export default class ApiService {
	private user: LoginReplyModel | undefined;
	protected request = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
	private handleLogout?: () => void;

	constructor(handleLogout?: () => void) {
		this.user = this.getUser();
		this.request.defaults.headers.common["Authorization"] = `Bearer ${this.user?.hashLogin}`;
		this.request.defaults.headers.common["X-FusoHorarioId"] = moment.tz.guess();
		this.handleLogout = handleLogout;
	}

	private getUser() {
		let userStorage = sessionStorage.getItem("user");
		if (userStorage) {
			return JSON.parse(userStorage) as LoginReplyModel;
		}
		return undefined;
	}

	private formatRequest<TData>(data: any): TData {
		if (data.result !== undefined) {
			return data.data
				? ({
						Result: data.result,
						Message: data.message,
						Data: data.data,
				  } as TData)
				: ({
						Result: data.result,
						Message: data.message,
				  } as TData);
		} else {
			return data;
		}
	}

	private async getRequest<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponseType<T>> {
		try {
			let request = await this.request.get<ApiResponseType<T>>(url, config);
			let response = this.formatRequest<ApiResponseType<T>>(request.data);
			if ((response.Result === 99 || response.Message === "Usuário não autenticado") && this.handleLogout) {
				this.handleLogout();
			}
			return response;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
	}

	private async postRequest<T>(url: string, body?: any, config?: AxiosRequestConfig): Promise<ApiResponseType<T>> {
		try {
			let request = await this.request.post<ApiResponseType<T>>(url, body, config);
			let response = this.formatRequest<ApiResponseType<T>>(request.data);
			if ((response.Result === 99 || response.Message === "Usuário não autenticado") && this.handleLogout) {
				this.handleLogout();
			}
			return response;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
	}

	private async deleteRequest<T>(url: string): Promise<ApiResponseType<T>> {
		try {
			let request = await this.request.delete<ApiResponseType<T>>(url);
			let response = this.formatRequest<ApiResponseType<T>>(request.data);
			if ((response.Result === 99 || response.Message === "Usuário não autenticado") && this.handleLogout) {
				this.handleLogout();
			}
			return response;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
	}

	public async getStatusVooCoords(missaoId: number, pilotoId: number, droneId: number, points: number) {
		var res = await this.getRequest<StatusVooCoordsModel[]>(`Missao/${missaoId}/statusVoo?pilotoId=${pilotoId}&droneId=${droneId}&maxPoints=${points}`);
		return res;
	}

	public async getStatusVooGroupedCoords(missaoId: number, pilotoId: number, droneId: number, maxGroups?: number) {
		var res = await this.getRequest<[StatusVooCoordsModel[]]>(`Missao/${missaoId}/statusVooGrouped?pilotoId=${pilotoId}&droneId=${droneId}&maxGroups=${maxGroups || ""}`);
		return res;
	}

	public async getStatusVoo(missaoId: number, pilotoId: number, droneId: number) {
		var res = await this.getRequest<StatusVooModel>(`Missao/${missaoId}/statusVoo/last?pilotoId=${pilotoId}&droneId=${droneId}`);
		return res;
	}

	public async doLogin(loginData: LoginPostData) {
		var res = await this.postRequest<LoginReplyModel>(`Auth/Login`, loginData);
		return res;
	}

	public async doLogout() {
		var res = await this.postRequest<any>(`Auth/logout`);
		return res;
	}

	public async getDroneStatusVoo(droneId: number, clienteId: number) {
		var res = await this.getRequest<StatusVooModel>(`Drone/${droneId}/statusVoo/last?clienteId=${clienteId || ""}`);
		return res;
	}

	public async getRotaMissao(missaoId: string) {
		var res = await this.getRequest<RotaMissaoModel[]>(`Missao/${missaoId}/rota`);
		return res;
	}

	public async getHome(clienteId: number) {
		var res = await this.getRequest<CoordinatesModel>(`Cliente/${clienteId}/home`);
		return res;
	}
	public async postHome(clienteId: number, params: CoordinatesModel) {
		var res = await this.postRequest<any>(`Cliente/${clienteId}/home`, params);
		return res;
	}

	public async getSOS(clienteId: number) {
		var res = await this.getRequest<CoordinatesModel>(`Cliente/${clienteId}/pontoSOS`);
		return res;
	}
	public async postSOS(clienteId: number, params: CoordinatesModel) {
		var res = await this.postRequest<any>(`Cliente/${clienteId}/pontoSOS`, params);
		return res;
	}

	public async getResumo(clienteId: number) {
		var res = await this.getRequest<ResumoClienteModel[]>(`Cliente/${clienteId}/resumo`);
		return res;
	}

	public async getResumoClientes() {
		var res = await this.getRequest<ResumoClienteModel[]>(`Cliente/resumo`);
		return res;
	}

	public async GetDronesCliente(clienteId: number) {
		var res = await this.getRequest<NamedEntity[]>(`Cliente/${clienteId}/drones`);
		return res;
	}

	public async GetLives() {
		var res = await this.getRequest<LiveViewModel[]>(`LiveView/lives`);
		return res;
	}

	public async GetMissaoLogDetalhe(missaoLogId: number) {
		var res = await this.getRequest<MissaoLogDetalhesModel>(`MissaoLog/${missaoLogId}/detalhe`);
		return res;
	}

	public async GetMissaoLogImagens(missaoLogId: number) {
		var res = await this.getRequest<MissaoLogImagensModel[]>(`MissaoLog/${missaoLogId}/imagens`);
		return res;
	}

	public async DeleteMissaoLogImagen(missaoLogId: number) {
		var res = await this.deleteRequest<any>(`MissaoLog/${missaoLogId}/imagen`);
		return res;
	}

	public async GetVerificaVideo(vooId: number) {
		var res = await this.getRequest<VerificaVideoModel>(`http://mp2.air.tec.br:3380/verificaVideo?vooId=${vooId}&hash=QOIA2dd3lop83UJDKSIPARHE82AH`);
		return res;
	}

	public async GetMissoesLogDrone(droneId: number) {
		var res = await this.getRequest<NamedEntity[]>(`Drone/${droneId}/logsMissao`);
		return res;
	}

	public async GetStatusVoo(statusVooId: number) {
		var res = await this.getRequest<StatusVooModel>(`StatusVoo/${statusVooId}`);
		return res;
	}

	public async GetExternalStreamHost(clientId: number) {
		var res = await this.getRequest<string>(`Cliente/${clientId}/stream/hostExterno`);
		return res;
	}

	public async GetLogsMissao(clientId: number, missaoId: number | undefined, pilotoId: number | undefined, droneId: number | undefined, startTicks: number, endTicks: number) {
		var res = await this.getRequest<MissaoLogModel[]>(
			`Cliente/${clientId}/logsMissao?${missaoId ? `missaoId=${missaoId}&` : ``}${pilotoId ? `pilotoId=${pilotoId}&` : ``}${
				droneId ? `droneId=${droneId}&` : ``
			}ticksInicio=${startTicks}&ticksFim=${endTicks}`
		);
		return res;
	}

	public async GetParametros(clientId: number) {
		var res = await this.getRequest<ParametroModel[]>(`Parametros?clienteId=${clientId}`);
		return res;
	}

	public async GetParametrosEmpresa(empresaId: number) {
		var res = await this.getRequest<ParametroModel[]>(`Parametros?empresaId=${empresaId}`);
		return res;
	}

	public async SetParametros(clientId: number, params: ParametroModel) {
		var res = await this.postRequest<any>(`Parametros?clienteId=${clientId}`, params);
		return res;
	}

	public async SetParametrosEmpresa(empresaId: number, params: ParametroModel) {
		var res = await this.postRequest<any>(`Parametros?empresaId=${empresaId}`, params);
		return res;
	}

	public async GetLogsDiarios(droneId: number, ticksInicio: number, ticksFim: number) {
		var res = await this.getRequest<LogDiarioLiteModel[]>(`LogDiario?droneId=${droneId}&ticksInicio=${ticksInicio}&ticksFim=${ticksFim}`);
		return res;
	}

	public async GetLogDiario(logId: number) {
		var res = await this.getRequest<LogDiarioModel>(`LogDiario/${logId}/detalhe`);
		return res;
	}

	public async GetCurrentWeather(clienteId: number) {
		var res = await this.getRequest<WeatherModel>(`Weather/current?clienteId=${clienteId}`);
		return res;
	}

	public async GetResumoDronesCliente(clienteId: number) {
		var res = await this.getRequest<ResumoDroneModel[]>(`Cliente/${clienteId}/drones/resumo`);
		return res;
	}

	public async GetResumoPilotoCliente(clienteId: number) {
		var res = await this.getRequest<ResumoPilotoModel[]>(`Cliente/${clienteId}/pilotos/resumo`);
		return res;
	}

	public async GetResumoBateriaCliente(clienteId: number) {
		var res = await this.getRequest<ResumoPilotoModel[]>(`Cliente/${clienteId}/baterias/resumo`);
		return res;
	}

	public async GetPiloto(pilotoId: number) {
		var res = await this.getRequest<PilotoGetModel>(`Piloto/${pilotoId}`);
		return res;
	}

	public async GetPilotosClienteCadastro(clienteId?: number) {
		var parametros = "?";
		if (clienteId) {
			parametros = parametros + `clienteId=${clienteId}`;
		}
		var res = await this.getRequest<PilotoGetModel[]>(`Piloto${parametros}`);
		return res;
	}

	public async GetCadastroPiloto(pilotoId: number) {
		var res = await this.getRequest<PilotoModel>(`Piloto/${pilotoId}/cadastro`);
		return res;
	}

	public async GetUsuarioPiloto(pilotoId: number) {
		var res = await this.getRequest<PilotoUsuarioModel>(`Piloto/${pilotoId}/usuario`);
		return res;
	}

	public async PostPiloto(piloto: PilotoModel, clienteId: number | undefined, empresaId: number | undefined) {
		var parametrosArray = [];
		if (clienteId) {
			parametrosArray.push(`clienteId=${clienteId}`);
		}
		if (empresaId) {
			parametrosArray.push(`empresaId=${empresaId}`);
		}
		var parametros = `?` + parametrosArray.join("&");
		var res = await this.postRequest<any>(`Piloto${parametros}`, piloto);
		return res;
	}

	public async DeletePiloto(pilotoId: number) {
		var res = await this.deleteRequest<any>(`Piloto/${pilotoId}`);
		return res;
	}

	public async GetCadastroDronesCliente(clienteId: number) {
		var res = await this.getRequest<DroneExtModel[]>(`Drone?clienteId=${clienteId}`);
		return res;
	}

	public async GetCadastroDrone(droneId: number) {
		var res = await this.getRequest<DroneExtModel>(`Drone/${droneId}`);
		return res;
	}

	public async PostCadastroDrone(drone: DroneModel, clienteId: number, empresaId: number) {
		var res = await this.postRequest<any>(`Drone?clienteId=${clienteId}&empresaId=${empresaId}`, drone);
		return res;
	}

	public async GetMissoesCliente(clienteId: number) {
		var res = await this.getRequest<MissaoModel[]>(`Cliente/${clienteId}/missoes?ativo=`);
		if (res.Data)
			res.Data = res.Data.map((x) => {
				x.waypoints = x.waypoints.map((y) => Object.assign(new MissaoWaypoint(), y));
				return Object.assign(new MissaoModel(), x);
			});
		return res;
	}

	public async GetCadastroMissao(missaoId: number) {
		var res = await this.getRequest<MissaoModel>(`Missao/${missaoId}`);
		if (res.Data) {
			res.Data.waypoints = res.Data.waypoints.map((y) => Object.assign(new MissaoWaypoint(), y));
			res.Data = Object.assign(new MissaoModel(), res.Data);
		}
		return res;
	}

	public async PostMissaoCadastro(missao: MissaoModel, missaoId: string | undefined, clienteId: number) {
		var res = await this.postRequest<any>(`Missao/${missaoId || ""}?clienteId=${clienteId}`, missao);
		return res;
	}

	public async GetStatusVooCsvLogMissao(logMissaoId: string) {
		var res = await this.request.get(`missaolog/${logMissaoId}/statusVoo/csv`, { responseType: "blob" });
		return res;
	}

	public async GetStatusVooCsvDrone(droneId: number, ticksInicio: number, ticksFim: number) {
		var res = await this.request.get(`drone/${droneId}/statusVoo/csv?ticksInicio=${ticksInicio}&ticksFim=${ticksFim}`, { responseType: "blob" });
		return res;
	}

	public async GetMedia(params: MediaParameters) {
		var res = await this.getRequest<MediaModel[]>(`media?droneId=${params.droneId || ""}&vooId=${params.vooId || ""}`);
		return res;
	}

	public async GetMediaBlob(url: string) {
		var res = await this.getRequest(url, { responseType: "blob" });
		return res;
	}

	public GetAllMediaUrl(params: MediaParameters) {
		return `media/downloadall?droneId=${params.droneId || ""}&vooId=${params.vooId || ""}&hashLogin=${this.user?.hashLogin}`;
	}

	public async GetAllMedia(params: MediaParameters) {
		var res = await this.getRequest(this.GetAllMediaUrl(params), { responseType: "blob" });
		return res;
	}

	public async GetAlertaCount(query: AlertaQueryInput) {
		var res = await this.postRequest<number>(`Alerta/Count`, query);
		return res;
	}

	public async SearchAlertas(query: AlertaSearchInput) {
		var res = await this.postRequest<PageModel<AlertaLiteOutput>>(`Alerta/Search`, query);
		return res;
	}

	public async GetAlerta(id: number) {
		var res = await this.getRequest<AlertaOutput>(`Alerta/${id}`);
		return res;
	}

	public async GetBaterias(empresaId: number) {
		var res = await this.getRequest<[Bateria]>(`Bateria?empresaId=${empresaId || ""}`);
		return res;
	}
	public async GetBateria(id: number) {
		var res = await this.getRequest<Bateria>(`Bateria/${id}`);
		return res;
	}
	public async GetAlertasBateria(bateriaId: number) {
		var res = await this.getRequest<AlertaBateria[]>(`Bateria/${bateriaId}/alertas`);
		return res;
	}
	public async postBateria(m: PostBateria) {
		var res = await this.postRequest<number>(`Bateria`, m);
		return res;
	}
	public async GetSituacoesBateria() {
		var res = await this.getRequest<[NamedCodedEntity]>(`Bateria/situacoes`);
		return res;
	}

	public async GetRelatorios(droneId?: number, bateriaId?: number) {
		var res = await this.getRequest<Relatorio[]>(`Relatorio?${bateriaId ? `bateriaId=${bateriaId}` : ""}${droneId ? `droneId=${droneId}` : ""}`);
		return res;
	}
	public async GetRelatorio(id: string) {
		var res = await this.getRequest<Relatorio>(`Relatorio/${id}`);
		return res;
	}
	public async PostRelatorio(m: PostRelatorio) {
		var res = await this.postRequest<string>(`Relatorio`, m);
		return res;
	}
	public async GetTiposRelatorios() {
		var res = await this.getRequest<NamedEntity[]>(`Relatorio/tipos`);
		return res;
	}
	public async GetExtensoesArquivosRelatorios() {
		var res = await this.getRequest<string[]>(`Relatorio/extensoes`);
		return res;
	}
	// public GetUrlUploadArquivoRelatorio(relatorioId: string) {
	// 	return `Relatorio/${relatorioId}/arquivo`;
	// }
	public async PostUploadArquivoRelatorio(relatorioId: string, a: any) {
		var res = await this.postRequest<string>(`Relatorio/${relatorioId}/arquivo`, a);
		return res;
	}
	public async GetArquivosRelatorio(relatorioId: string) {
		var res = await this.getRequest<ArquivoRelatorio[]>(`Relatorio/${relatorioId}/arquivos`);
		return res;
	}
	public async DeletarArquivoRelatorio(relatorioId: string, arquivoId: string) {
		var res = await this.deleteRequest<object>(`Relatorio/${relatorioId}/arquivo/${arquivoId}`);
		return res;
	}
	public async PostTipoRelatorio(m: NamedCodedEntity) {
		var res = await this.postRequest<string>(`Relatorio/tipo`, m);
		return res;
	}

	public async GetHomeMissao(missaoId: string) {
		var res = await this.getRequest<CoordinatesModel>(`Missao/${missaoId}/home`);
		return res;
	}

	public async GetEmpresas() {
		var res = await this.getRequest<NamedEntity[]>(`Empresa`);
		return res;
	}
	public async GetEmpresa(id: number) {
		var res = await this.getRequest<PostEmpresa>(`Empresa/${id}`);
		return res;
	}
	public async PostEmpresa(m: PostEmpresa) {
		var res = await this.postRequest<number>(`Empresa`, m);
		return res;
	}

	public async GetClientes(empresaId?: number) {
		var eId = empresaId && empresaId > 0 ? `/${empresaId}` : "";
		var res = await this.getRequest<ClienteSlim[]>(`Empresa${eId}/cliente`);
		return res;
	}
	public async GetCadastroCliente(clienteId: number) {
		var res = await this.getRequest<CadastroCliente>(`Cliente/${clienteId}/cadastro`);
		return res;
	}
	public async PostCliente(m: CadastroCliente) {
		var res = await this.postRequest<number>(`Cliente`, m);
		return res;
	}

	public async GetAdmins(empresaId?: number) {
		var eId = empresaId && empresaId > 0 ? `/${empresaId}` : "";
		var res = await this.getRequest<AdminSlim[]>(`Empresa${eId}/admin`);
		return res;
	}
	public async GetCadastroAdmin(adminId: number) {
		var res = await this.getRequest<CadastroAdmin>(`Empresa/admin/${adminId}`);
		return res;
	}
	public async PostAdmin(m: PostAdmin, empresaId: number) {
		var res = await this.postRequest<number>(`Empresa/${empresaId}/admin`, m);
		return res;
	}
}
