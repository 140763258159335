import { Alert, Button, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiAccountTieHatOutline, mdiCardRemoveOutline } from "@mdi/js";

import { SelectGroupStyle } from "../../config/select";
import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";

import Layout from "../../components/Layout";
import PilotoCard from "../../components/PilotoCard";
import { useState } from "react";
import { Textos } from "../../config/defines";

export default function Pilotos() {
	const navigate = useNavigate();
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formEmpresa, setFormEmpresa] = useState<{ label: string; value: number } | null>();
	const [formCliente, setFormCliente] = useState<{ label: string; value: number } | null>();

	const { data, isLoading, isRefetching } = useQuery(["pilotos", cliente!.id], () => fetchData());

	async function fetchData() {
		return await apiService.GetPilotosClienteCadastro();
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap gap-2" style={{ minHeight: 38 }}>
				<div className="d-flex align-items-center">
					<Icon path={mdiAccountTieHatOutline} size={1} className="me-1" /> Pilotos
					{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				</div>
				<div className="d-flex gap-2 float-right ms-auto">
					<InputGroup style={{ minWidth: 300 }}>
						<InputGroup.Text className="bg-white">{Textos[`${process.env.REACT_APP_TENANT}`].empresa}</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							className="fs-6 bg-white rounded"
							value={formEmpresa}
							options={user!.empresa.map((empresa: any) => {
								return { label: empresa.nome, value: empresa.id };
							})}
							onChange={(value) => {
								setFormEmpresa(value);
								setFormCliente(null);
							}}
							isClearable={true}
							styles={SelectGroupStyle}
						/>
					</InputGroup>
					<InputGroup style={{ minWidth: 300 }}>
						<InputGroup.Text className="bg-white">{Textos[`${process.env.REACT_APP_TENANT}`].cliente}</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							className="fs-6 bg-white rounded"
							value={formCliente}
							options={user!.cliente
								.filter((cliente) => cliente.empresaId === formEmpresa?.value)
								.map((cliente) => {
									return { label: cliente.nome, value: cliente.id };
								})}
							onChange={(value) => {
								setFormCliente(value);
							}}
							isClearable={true}
							styles={SelectGroupStyle}
						/>
					</InputGroup>
					<Button
						variant="air-blue"
						className="text-white"
						onClick={() => {
							navigate("/pilotoFormulario");
						}}
					>
						Cadastrar
					</Button>
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<PilotoCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.filter((item) => {
							let empresa = formEmpresa ? item.empresaId === formEmpresa?.value : true;
							let cliente = formCliente ? item.clienteId === formCliente?.value : true;
							return empresa && cliente;
						}).length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							data?.Data?.filter((item) => {
								let empresa = formEmpresa ? item.empresaId === formEmpresa?.value : true;
								let cliente = formCliente ? item.clienteId === formCliente?.value : true;
								return empresa && cliente;
							}).map((item, index) => {
								return (
									<Col md={12} key={index}>
										<PilotoCard data={item} />
									</Col>
								);
							})
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}
