import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { mdiAccountMultipleOutline, mdiCardRemoveOutline, mdiChevronRight, mdiDomainPlus } from "@mdi/js";
import Icon from "@mdi/react";

import ApiService from "../../services/ApiService";
import { useAuth } from "../../context/AuthContext";

import CadastroClienteCard from "../../components/CadastroClienteCard";
import Layout from "../../components/Layout";
import { Textos } from "../../config/defines";

export default function CadastrosClientes() {
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const { data, isLoading, isRefetching } = useQuery(["cadastros", "clientes"], () => fetchData());

	async function fetchData() {
		return await apiService.GetClientes();
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/cadastros"} className="d-flex text-decoration-none">
					<Icon path={mdiDomainPlus} size={1} className="me-1" /> Cadastros
				</Link>
				<Icon path={mdiChevronRight} size={1} className="ms-1 me-1" />
				<Icon path={mdiAccountMultipleOutline} size={1} className="me-1" /> {Textos[`${process.env.REACT_APP_TENANT}`].clientes}
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button
						variant="air-blue"
						className="text-white"
						onClick={() => {
							navigate("/cadastroClienteFormulario");
						}}
					>
						Cadastrar
					</Button>
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<CadastroClienteCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							data?.Data?.map((item, index) => {
								return (
									<Col md={12} key={index}>
										<CadastroClienteCard data={item} />
									</Col>
								);
							})
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}
