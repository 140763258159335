import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { ClienteSlim } from "../../models/ClienteModel";
import ListCard from "../ListCard";
import { Textos } from "../../config/defines";

type CadastroClienteCardPropsContent = {
	data: ClienteSlim;
	skeleton?: never;
};

type CadastroClienteCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type CadastroClienteCardProps = CadastroClienteCardPropsContent | CadastroClienteCardPropsSkeleton;

export default function CadastroClienteCard(props: CadastroClienteCardProps) {
	const navigate = useNavigate();

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/cadastroClienteFormulario/" + props.data.id);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>{Textos[`${process.env.REACT_APP_TENANT}`].empresa}</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.empresa?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}
