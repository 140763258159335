import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { mdiAccountMultiple, mdiCardRemoveOutline } from "@mdi/js";
import Icon from "@mdi/react";

import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";

import ClienteResumoCard from "../../components/ClienteResumoCard";
import Layout from "../../components/Layout";
import { Textos } from "../../config/defines";

export default function Resumo() {
	const navigate = useNavigate();
	const { cliente, handleCliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const { data, isLoading, isRefetching } = useQuery(["cliente", "resumo"], () => fetchData());

	async function fetchData() {
		let resp = await apiService.getResumoClientes();
		if (resp.Data?.length === 1 && !cliente) {
			handleCliente(resp.Data[0]);
			navigate("/dashboard");
		}
		return resp;
	}

	return (
		<Layout cliente={false}>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiAccountMultiple} size={1} className="me-1" /> Selecionar {Textos[`${process.env.REACT_APP_TENANT}`].cliente}
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Row className="mb-4">
				{isLoading ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<ClienteResumoCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							data?.Data?.map((item, index) => {
								return (
									<Col md={12} key={index}>
										<ClienteResumoCard data={item} />
									</Col>
								);
							})
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}
