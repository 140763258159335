import { useNavigate } from "react-router-dom";
// import { Button } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
// import { mdiFileDocumentOutline } from "@mdi/js";

import { Bateria } from "../../models/BateriaModel";

import ListCard from "../ListCard";
import { Textos } from "../../config/defines";

type BateriaCardPropsContent = {
	data: Bateria;
	skeleton?: never;
};

type BateriaCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type BateriaCardProps = BateriaCardPropsContent | BateriaCardPropsSkeleton;

export default function BateriaCard(props: BateriaCardProps) {
	const navigate = useNavigate();

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/bateriaFormulario/" + props.data.id);
		}
	}

	// function handleClickRelatorio(e: any) {
	// 	e.stopPropagation();
	// 	if (!props.skeleton && props.data) {
	// 		navigate("/relatorios?bateriaId=" + props.data.id);
	// 	}
	// }

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>N˚ Série</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.numeroSerie ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>{Textos[`${process.env.REACT_APP_TENANT}`].cliente}</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.cliente?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Drone</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.drone?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Situação</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.situacao?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>N˚ Voos</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.estatisticas.numeroVoos ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Tempo Vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.estatisticas.tempoVoo ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Ciclos Carga</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.estatisticas.ciclosCarga ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Data Último Vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.estatisticas.dataUltimoVoo.dateString ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					{/* <Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRelatorio}>
						<Icon path={mdiFileDocumentOutline} size={1} />
					</Button> */}
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}
