import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMaskInput } from "react-imask";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiAccountTieOutline, mdiChevronLeft } from "@mdi/js";

import { CPFMask, CelularMask, TelefoneMask, Textos } from "../../config/defines";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { PostAdmin } from "../../models/AdminModel";

import Layout from "../../components/Layout";
import { SelectStyle } from "../../config/select";

const toastTitle = "Administrador";

export default function CadastroAdministradorFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { user, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formEmpresa, setFormEmpresa] = useState<{ label: string; value: number } | undefined>();
	const [formUsuario, setFormUsuario] = useState<string>();
	const [formNome, setFormNome] = useState<string>();
	const [formSenha, setFormSenha] = useState<string>();
	const [formCodigo, setFormCodigo] = useState<string>();
	const [formCPF, setFormCPF] = useState<string>();
	const [formRG, setFormRG] = useState<string>();
	const [formTelefone, setFormTelefone] = useState<string>();
	const [formCelular, setFormCelular] = useState<string>();
	const [formEmail, setFormEmail] = useState<string>();
	const [formPermissoes, setFormPermissoes] = useState<number[]>();

	const queryEmpresas = useQuery(["cadastros", "empresas"], () => fetchDataEmpresas());
	const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(["cadastros", "administradores", id], () => fetchData(id), { enabled: id !== undefined });
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	useEffect(() => {
		if (data && data.Data?.empresaId && queryEmpresas.data) {
			let empresa = queryEmpresas.data.Data?.find((item) => {
				return item.id === data.Data?.empresaId;
			});
			if (empresa) {
				setFormEmpresa({ label: empresa.nome, value: empresa.id });
			}
		}
	}, [data, queryEmpresas.data]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetCadastroAdmin(id);
		if (resp.Result === 1 && resp.Data) {
			setFormUsuario(resp.Data.usuario);
			setFormNome(resp.Data.nome);
			setFormSenha(resp.Data.senha);
			setFormCodigo(resp.Data.codigo);
			setFormCPF(resp.Data.cpf);
			setFormRG(resp.Data.rg);
			setFormTelefone(resp.Data.telefone);
			setFormCelular(resp.Data.celular);
			setFormEmail(resp.Data.email);
			setFormPermissoes(resp.Data.permissoes);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return resp;
	}

	async function fetchDataEmpresas() {
		return await apiService.GetEmpresas();
	}

	async function mutateData(data: PostAdmin) {
		return await apiService.PostAdmin(data, formEmpresa!.value);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["cadastros", "administradores", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/cadastroAdministradorFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/cadastrosAdministradores");
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: PostAdmin = {
			id: Number(id) ?? null,
			usuario: formUsuario!,
			nome: formNome!,
			senha: formSenha!,
			codigo: formCodigo!,
			cpf: formCPF!,
			rg: formRG!,
			telefone: formTelefone!,
			celular: formCelular!,
			email: formEmail!,
			permissoes: formPermissoes!,
		};

		mutation.mutate(data);
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/cadastrosAdministradores"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiAccountTieOutline} size={1} className="me-1" />
					Formulário de Administrador
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Form onSubmit={handleSubmit}>
				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="empresa">
							<Form.Label>{Textos[`${process.env.REACT_APP_TENANT}`].empresa}</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formEmpresa}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={queryEmpresas.data?.Data?.map((drone: any) => {
									return { label: drone.nome, value: drone.id };
								})}
								onChange={(value) => {
									if (value) setFormEmpresa(value);
								}}
								isDisabled={!formStatus || !!id}
								styles={SelectStyle}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="usuario">
							<Form.Label>Usuário</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o usuário"
								value={formUsuario}
								onChange={(event) => {
									setFormUsuario(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="senha">
							<Form.Label>Senha</Form.Label>
							<Form.Control
								type="password"
								placeholder="Informe aqui a senha"
								value={formSenha}
								onChange={(event) => {
									setFormSenha(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="codigo">
							<Form.Label>Código</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o código"
								value={formCodigo}
								onChange={(event) => {
									setFormCodigo(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="cpf">
							<Form.Label>CPF</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={CPFMask}
								type="text"
								placeholder="Informe aqui o CPF"
								value={formCPF}
								onChange={(event) => {
									setFormCPF(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="RG">
							<Form.Label>RG</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o RG"
								value={formRG}
								onChange={(event) => {
									setFormRG(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="telefone">
							<Form.Label>Telefone</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={TelefoneMask}
								type="text"
								placeholder="Informe aqui o telefone"
								value={formTelefone}
								onChange={(event) => {
									setFormTelefone(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="celular">
							<Form.Label>Celular</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={CelularMask}
								type="text"
								placeholder="Informe aqui o celular"
								value={formCelular}
								onChange={(event) => {
									setFormCelular(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label>E-mail</Form.Label>
							<Form.Control
								type="email"
								placeholder="Informe aqui o e-mail"
								value={formEmail}
								onChange={(event) => {
									setFormEmail(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
					</Card.Body>
				</Card>

				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Permissões</Card.Header>
					<Card.Body>
						<Row>
							{user!.permissoes.map((permissao, key: number) => {
								return (
									<Col lg={4} key={key}>
										<Form.Group className="mb-3" controlId={permissao.codigo}>
											<Form.Label>{permissao.nome}</Form.Label>
											<Form.Check
												type="switch"
												checked={formPermissoes?.includes(permissao.id)}
												onChange={(e) => {
													if (e.currentTarget.checked) {
														setFormPermissoes([...(formPermissoes ?? []), permissao.id]);
													} else {
														setFormPermissoes(
															formPermissoes?.filter((item) => {
																return item !== permissao.id;
															})
														);
													}
												}}
												disabled={!formStatus}
											/>
										</Form.Group>
									</Col>
								);
							})}
						</Row>
					</Card.Body>
				</Card>

				<div className="mb-4">
					{!formStatus ? (
						<Button
							className="me-2"
							variant="dark"
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setFormStatus(true);
							}}
						>
							Editar Informações
						</Button>
					) : (
						<>
							<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
								{formSaving ? (
									<>
										<Spinner animation="border" size="sm" className="me-2" /> Salvando
									</>
								) : (
									"Salvar Informações"
								)}
							</Button>
							{id && (
								<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
									Cancelar
								</Button>
							)}
						</>
					)}
				</div>
			</Form>
		</Layout>
	);
}
